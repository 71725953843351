<template>
<div class="tgju-widget light post" style="box-shadow: none">
    <div class="post-row">
        <PostItem :post="post" v-if="post" ref="postComment-PostItem"></PostItem>
    </div>
    <template v-if="comments.length">
        <div class="user-block-comments">
            <ul class="user-block-comment mb-0">
                <li class="user-block-comment-item" v-for="comment in comments" :key="comment.id">
                    <CommentItem :comments="comments" :comment="comment" :group_id="newPost.group_id"></CommentItem>
                </li>
            </ul>
        </div>
    </template>
    <template v-else>
        <div class="no-content">درحال حاضر هیچ نظری برای نمایش وجود ندارد</div>
    </template>
    <template>
        <!-- فرم ارسال توییت -->
        <PostSend v-if="user" :newPost="newPost" ref="postComment-postSend"></PostSend>
    </template>
</div>
</template>

<style lang="scss">
.user-block-comment-items ul li.like {
    position: relative;
    top: 3px;
    float: none;
}

.user-block-comment-item-content .emoji {
    width: 17px !important;
    margin-right: 3px !important;
}

.user-block-comment-item-content .post-img {
    max-width: 100%;
    margin-top: 20px;
}
</style>

<script>
// این کامپوننت برای مودال نمایش کامنت ها در پست ها استفاده میشود
// وضعیت فعلی : غیرفعال می باشد
import moment from "moment-jalaali";
import PostSend from '@/components/Posts/PostSend.vue'
import PostItem from '@/components/Posts/PostItem.vue'
import CommentItem from '@/components/Posts/CommentItem.vue'

export default {
    name: 'PostCommentsModal',
    props: ['data'],
    components: {
        PostSend,
        PostItem,
        CommentItem
    },
    data: function () {
        return {
            newPost: {
                content: '',
                type: null,
                group_id: null,
                attachments: [],
                parent_id: null,
                parent: null,
            },
            post: null,
            user: this.$helpers.userSync(),
        }
    },
    computed: {
        posts: {
            get() {
                return this.data.posts.data;
            },
            set(value) {
                this.data.posts.data = value;
            }
        },
        comments: {
            get() {
                return this.data.comments;
            },
            set(value) {
                this.data.comments = value;
            }
        }
    },
    watch: {
        data: {
            handler: function (newValue, oldValue) {
                if (Object.keys(newValue).length) {
                    this.post = this.data.post;

                    this.newPost.type = 'comment';
                    this.newPost.parent_id = this.post.id;
                    this.newPost.parent = this.post;
                    this.newPost.group_id = this.data.group_id;

                    if (typeof oldValue == 'undefined' || !Object.keys(oldValue).length || this.data.post.id != oldValue.post.id) {
                        this.loadData();
                    }
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        // این متد وظیفه دریافت اطلاعات از سرور را دارد
        loadData() {
            let api_data = {
                parent_id: this.post.id,
                username: this.$route.params.username ? this.$route.params.username : this.$store.state.username
            };

            this.$helpers.makeRequest('GET', '/post/comments', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let response = api_response.data.response;
                    let comments = [];
                    response.comments.forEach(comment => {
                        comments.push(comment);
                    });
                    this.comments = comments;
                    this.$parent.emitData('loadComments', { comments: this.$helpers.unbindObject(this.comments) });
                }
            });
        },
        setCaretToEnd() {
            let postSendComponent = this.$refs['postComment-postSend'];
            postSendComponent.setCaretToEnd(postSendComponent.$refs['textarea-post']);
        },
        scrollTo(container) {
            let postSendComponent = this.$refs['postComment-postSend'];
            postSendComponent.scrollTo(container);
        },
    },
}
</script>
